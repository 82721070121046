import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'
import Apiservice from '../services/Apiservice';
import Navigationbar from '../components/Navigationbar';
import Footer from '../components/Footer';
import CommonFunctions from '../components/CommonFunctions';

const PlayerDetails = () => {
    const { id } = useParams();

  const [tournamentId, setTournamentId] = useState('2');
  const [battingStats, setBattingStats] = useState([]);
  const [bowlingStats, setBowlingStats] = useState([]);
  const [playerDetails, setPlayerDetails] = useState([]);
  const [totalStats, setTotalStats] = useState([]);
  const [playerName, setPlayerName] = useState('');
  // Access URL parameters using useParams

  useEffect(() => {
    const fetchPost = async () => {
      try {
          const response = await Apiservice.getPlayerById(id, tournamentId)
          setBattingStats(response.data.batting_stats);
          setBowlingStats(response.data.bowling_stats);
          setPlayerDetails(response.data.player_details);
          setTotalStats(response.data.total_stats);
          setPlayerName(response.data.player_details.full_name)
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [id, tournamentId]); // Include match.params.id in the dependency array


  return (
    <React.Fragment>
        <div className="bg-fluid"></div>
        <Navigationbar />
        <div className='container-fluid'> 
          <nav aria-label="breadcrumb" className=''>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" className="text-muted text-decoration-none">Home</Link></li>
                    <li className="breadcrumb-item text-muted">Player Profile</li>
                    <li className="breadcrumb-item text-white active" aria-current="page">{playerDetails.full_name}</li>
                </ol>
            </nav>     
          <div className='row'>      
            <div className='col-md-3'>      
                <aside>
                    <div className='card shadow'>
                        <div className='card-title px-3 py-2 bg-info mb-0'>
                            <h1 className='m-0 fs-5 text-white'>{playerDetails.full_name}</h1>
                        </div>
                        <div className='text-center'>
                            <img src={`images/players/${playerName.toLowerCase().replace(/\s+/g, '-')}.webp`} alt={playerDetails.full_name} style={{width:'200px'}} onError={(e) => { e.target.onerror = null; e.target.src = 'images/players/default.webp'; }} />
                        </div>
                        <div className='card-body p-0 m-0'>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">Country: <strong>{playerDetails.country}</strong></li>
                                <li class="list-group-item">DoB: <strong>{CommonFunctions.getPostDateFormat(playerDetails.date_of_birth)}</strong></li>
                                <li class="list-group-item">Role: <strong>{playerDetails.role}</strong></li>
                                <li class="list-group-item">Teams: <strong>{playerDetails.team_names}</strong></li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className='card shadow my-3'>
                        <div className='card-title px-3 py-2 bg-info mb-0'>
                            <h3 className='m-0 fs-6 text-white'>{playerDetails.full_name} WPL 2024 Stats</h3>
                        </div>
                        <div className='card-body p-0 m-0'>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">Total Runs: <strong>{totalStats.total_runs}</strong></li>
                                <li class="list-group-item">Total Fours: <strong>{totalStats.total_fours}</strong></li>
                                <li class="list-group-item">Total Sixes: <strong>{totalStats.total_sixes}</strong></li>
                                <li class="list-group-item">Total Wickets: <strong>{totalStats.total_wickets}</strong></li>
                            </ul>
                        </div>
                    </div>
                </aside>
            </div>
            <div className='col-md-9'>    
                <main>
                    <section>
                        <div className='card shadow'>
                            <div className='card-title px-3 py-2 bg-dark-subtle mb-0'>
                                <h2 className='m-0 fs-6'>{playerDetails.full_name} Batting Stats</h2>
                            </div>
                            <div className='card-body p-1 m-0'>
                                <table className="table table-bordered table-sm m-0">
                                    <thead>
                                        <tr>
                                            <th>Against Team</th>
                                            <th>Runs</th>
                                            <th>Balls</th>
                                            <th>Fours</th>
                                            <th>Sixes</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        {battingStats && battingStats.length > 0 && (
                                            <React.Fragment>
                                                {battingStats.map((items, index) => {
                                                    const { opposing_team_shortcode, runs, balls_faced, fours, sixes } = items;
                                                    if (opposing_team_shortcode !== null || runs !== null || balls_faced !== null || fours !== null || sixes !== null) {
                                                        return (
                                                            <tr key={index}>
                                                                <td>vs {opposing_team_shortcode}</td>
                                                                <td>{runs !== null ? runs : 0 }</td>
                                                                <td>{balls_faced !== null ? balls_faced : 0}</td>
                                                                <td>{fours !== null ? fours : 0}</td>
                                                                <td>{sixes !== null ? sixes : 0}</td>
                                                            </tr>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </React.Fragment>
                                        )}


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className='card shadow my-3'>
                            <div className='card-title px-3 py-2 bg-dark-subtle mb-0'>
                                <h2 className='m-0 fs-6'>{playerDetails.full_name} Bowling Stats</h2>
                            </div>
                            <div className='card-body p-1 m-0'>
                                <table className="table table-bordered table-sm m-0">
                                    <thead>
                                        <tr>
                                            <th>Against Team</th>
                                            <th>Overs</th>
                                            <th>Maidens</th>
                                            <th>Dots</th>
                                            <th>Runs</th>
                                            <th>Wickets</th>
                                            <th>Economy</th>
                                            <th>Fours</th>
                                            <th>Sixes</th>
                                            <th>Wd</th>
                                            <th>Nb</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                    {bowlingStats && bowlingStats.length > 0 ? (
                                        bowlingStats.map( (items, index) => (
                                            <tr key={index}>
                                                <td>vs {items.opposing_team_shortcode}</td>
                                                <td>{parseFloat(items.overs).toFixed(1)}</td>
                                                <td>{items.maidens}</td>
                                                <td>{items.dots_bowled}</td>
                                                <td>{items.runs_given}</td>
                                                <td>{items.wickets}</td>
                                                <td>{items.economy_rate}</td>
                                                <td>{items.fours_given}</td>
                                                <td>{items.sixes_given}</td>
                                                <td>{items.wd_bowled}</td>
                                                <td>{items.nb_bowled}</td>
                                            </tr>                
                                        ))
                                        ) : (
                                            <tr>
                                                <td colSpan="10">No bowling stats available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default PlayerDetails;
