import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'
import Navigationbar from '../components/Navigationbar'
import Apiservice from '../services/Apiservice';
import CommonFunctions from '../components/CommonFunctions';

const MatchDetails = () => {
    const { id, wplmatch } = useParams();

    const [tournamentId, setTournamentId] = useState('2')   
    const [battingInnings1, setBattingInnings1] = useState([])   
    const [bowlingInnings1, setBowlingInnings1] = useState([])   
    const [battingInnings2, setBattingInnings2] = useState([])   
    const [bowlingInnings2, setBowlingInnings2] = useState([])  
    const [todaySfs, setTodaySfs] = useState([]) 
    const [pointsTable, setPointsTable] = useState([])
    const [posts, setPosts] = useState([])
    const [matchStats, setMatchStats] = useState({})   
    
    const formattedMatchName = wplmatch
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await Apiservice.getmatchdetails(id, tournamentId);
                setBattingInnings1(response.data.match_stats.batting_innings_1);
                setBowlingInnings1(response.data.match_stats.bowling_innings_1);
                setBattingInnings2(response.data.match_stats.batting_innings_2);
                setBowlingInnings2(response.data.match_stats.bowling_innings_2);
                setMatchStats(response.data.matchdetails);
                setPointsTable(response.data.total_points);
                setTodaySfs(response.data.total_csf[0]);
                setPosts(response.data.posts);
            } catch (error) {
                console.error('Error fetching post:', error);
            }
        };
    
        fetchPost();
    }, [id, tournamentId, wplmatch]); // Include tournamentId along with id in the dependency array
    

  return (
    <React.Fragment>
        <div className="bg-fluid"></div>
        <Navigationbar />
        <div className='container-fluid'> 
          <nav aria-label="breadcrumb" className=''>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" className="text-muted text-decoration-none">Home</Link></li>
                    <li className="breadcrumb-item text-muted">{(matchStats.match_no == '21' ? 'Eliminator' : (matchStats.match_no == '22') ? 'Finals' : matchStats.match_no)} Match</li>
                    <li className="breadcrumb-item text-white active" aria-current="page">{formattedMatchName}</li>
                </ol>
            </nav>     
          <div className='row'>      
            <div className='col-md-9'>
                <main>
                    <section>
                        <div class="d-flex bg-white shadow mb-4 rounded">
                            <div className="d-flex flex-shrink-0 align-items-center">
                                    <img src={`images/logos/${(matchStats.team1_shortcode?.toLowerCase())}-sm_wplcricinfo.jpg`} style={{width: '55px'}} alt={matchStats.team1_shortcode} />
                                    <span className='mx-2'>vs</span> 
                                    <img src={`images/logos/${(matchStats.team2_shortcode?.toLowerCase())}-sm_wplcricinfo.jpg`} style={{width: '55px'}} alt={matchStats.team2_shortcode} />
                                
                            </div>

                            <div class="flex-grow-1 ms-3">
                                <span><span className="badge rounded-pill text-bg-primary">{(matchStats.match_no == '21' ? 'Eliminator' : (matchStats.match_no == '22') ? 'Finals' : matchStats.match_no)} Match</span> <small>{matchStats.team1_shortcode} vs {matchStats.team2_shortcode}</small>  <small> ({matchStats.playing_time}) {CommonFunctions.getDateFormat(matchStats.match_date)}, {CommonFunctions.getFormattedTime(matchStats.match_time)}</small></span>
                                <h2 className='my-1 fs-5'> {matchStats.team1_name} vs {matchStats.team2_name}</h2>
                                <p className=''>{matchStats.venue_name}, {matchStats.city}, {matchStats.venue_country}</p>
                            </div>
                        </div>

                        <div className="alert alert-primary shadow py-0 d-flex align-items-center justify-content-between" role="alert">
                            <span className='text-uppercase'>
                                <i className='bi bi-trophy text-dark me-2'></i>
                                {(matchStats.winner_id === matchStats.team1_id) ? matchStats.team1_name : matchStats.team2_name}
                            </span>
                            <span className='float-end'>
                                Player of the match:
                                <img
                                    src={`images/players/${(matchStats.pom_player_name)?.toLowerCase().replace(/\s+/g, '-')}.webp`}
                                    alt={matchStats.pom_player_name}
                                    title={matchStats.pom_player_name}
                                    className='rounded'
                                    style={{ width: '65px' }}
                                />
                            </span>
                        </div>


                        <ul className="nav nav-pills  bg-white" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">First Innings</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Second Innings</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                                <div className='card shadow'>
                                    <div className='card-body p-1 m-0'>
                                        <table className="table table-bordered table-sm m-0">
                                            <thead>
                                                <tr>
                                                    <th colSpan={6} valign='middle'> {matchStats.team1_shortcode} Batting Innings</th>
                                                </tr>
                                                <tr>
                                                    <th>Player Name</th>
                                                    <th></th>
                                                    <th>Runs</th>
                                                    <th>Balls</th>
                                                    <th>Fours</th>
                                                    <th>Sixes</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-group-divider">

                                                {battingInnings1.map((items, index) => {
                                                    return <tr key={index}>
                                                        <td>{items.player_name}</td>
                                                        <td>
                                                            {
                                                                (items.out_type === null && items.runs === null) 
                                                                ? 
                                                                <React.Fragment></React.Fragment>
                                                                :
                                                                (items.out_type === 'caught') 
                                                                ? 
                                                                <React.Fragment><span className='me-1'>c</span>{items.fielder_name}<span className="mx-1">b</span>{items.bowler_name}</React.Fragment>
                                                                :
                                                                (items.out_type === 'bowled') 
                                                                ?
                                                                <React.Fragment><span className="mx-1">b</span>{items.bowler_name}</React.Fragment>
                                                                :
                                                                (items.out_type === 'lbw') 
                                                                ?
                                                                <React.Fragment><span className="mx-1">lbw</span>{items.bowler_name}</React.Fragment>
                                                                :
                                                                (items.out_type === 'runout') 
                                                                ?
                                                                <React.Fragment><span className="mx-1">runout ({items.fielder_name})</span>{items.bowler_name}</React.Fragment>
                                                                :
                                                                <React.Fragment>not out</React.Fragment>
                                                            }
                                                        </td>
                                                        <td>{items.runs}</td>
                                                        <td>{items.balls_faced}</td>
                                                        <td>{items.fours}</td>
                                                        <td>{items.sixes}</td>
                                                    </tr>
                                                })}
                                                    
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={2}><strong>Total</strong></td>
                                                    <td colSpan={4}>
                                                    <strong>
                                                        {battingInnings1.reduce((total, currentItem) => total + parseInt(currentItem.runs), 0)}
                                                        /{bowlingInnings1.reduce((total, currentItem) => total + parseInt(currentItem.wickets), 0)}
                                                    </strong>
                                                        <span className='ms-2'>
                                                            Overs <strong>({bowlingInnings1.reduce((total, currentItem) => total + parseFloat(currentItem.overs), 0)} /20)</strong>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div className='card my-2 shadow'>
                                    <div className='card-body p-0'>
                                    <table className="table table-bordered table-sm m-0">
                                            <thead>
                                                <tr>
                                                    <th colSpan={6} valign='middle'> {matchStats.team2_shortcode} Bowling Innings</th>
                                                </tr>
                                                <tr>
                                                    <th>Player Name</th>
                                                    <th>Overs</th>
                                                    <th>Maidens</th>
                                                    <th>Dots</th>
                                                    <th>Runs</th>
                                                    <th>Wickets</th>
                                                    <th>Economy</th>
                                                    <th>Fours</th>
                                                    <th>Sixes</th>
                                                    <th>Nb</th>
                                                    <th>Wides</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-group-divider">

                                                {bowlingInnings1.map((items, index) => {
                                                    return <tr key={index}>
                                                        <td>{items.player_name}</td>
                                                        <td>{parseFloat(items.overs).toFixed(1)}</td>
                                                        <td>{items.maidens}</td>
                                                        <td>{items.dots_bowled}</td>
                                                        <td>{items.runs_given}</td>
                                                        <td>{items.wickets}</td>
                                                        <td>{items.economy_rate}</td>
                                                        <td>{items.fours_given}</td>
                                                        <td>{items.sixes_given}</td>
                                                        <td>{items.nb_bowled}</td>
                                                        <td>{items.wd_bowled}</td>
                                                    </tr>
                                                })}
                                                    
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">
                                <div className='card shadow'>
                                    <div className='card-body p-1 m-0'>
                                        <table className="table table-bordered table-sm m-0">
                                            <thead>
                                                <tr>
                                                    <th colSpan={6} valign='middle'> {matchStats.team2_shortcode} Batting Innings</th>
                                                </tr>
                                                <tr>
                                                    <th>Player Name</th>
                                                    <th></th>
                                                    <th>Runs</th>
                                                    <th>Balls</th>
                                                    <th>Fours</th>
                                                    <th>Sixes</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-group-divider">

                                                {battingInnings2.map((items, index) => {
                                                    return <tr key={index}>
                                                        <td>{items.player_name}</td>
                                                        <td>
                                                            {
                                                                (items.out_type === null && items.runs === null) 
                                                                ? 
                                                                <React.Fragment></React.Fragment>
                                                                :
                                                                (items.out_type === 'caught') 
                                                                ? 
                                                                <React.Fragment><span className='me-1'>c</span>{items.fielder_name}<span className="mx-1">b</span>{items.bowler_name}</React.Fragment>
                                                                :
                                                                (items.out_type === 'bowled') 
                                                                ?
                                                                <React.Fragment><span className="mx-1">b</span>{items.bowler_name}</React.Fragment>
                                                                :
                                                                (items.out_type === 'lbw') 
                                                                ?
                                                                <React.Fragment><span className="mx-1">lbw</span>{items.bowler_name}</React.Fragment>
                                                                :
                                                                (items.out_type === 'runout') 
                                                                ?
                                                                <React.Fragment><span className="mx-1">runout ({items.fielder_name})</span>{items.bowler_name}</React.Fragment>
                                                                :
                                                                <React.Fragment>not out</React.Fragment>
                                                            }
                                                        </td>
                                                        <td>{items.runs}</td>
                                                        <td>{items.balls_faced}</td>
                                                        <td>{items.fours}</td>
                                                        <td>{items.sixes}</td>
                                                    </tr>
                                                })}
                                                    
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={2}>Total</td>
                                                    <td colSpan={4}>
                                                        {battingInnings2.reduce((total, currentItem) => {
                                                            // Check if currentItem.runs is a valid number
                                                            const runs = parseInt(currentItem.runs);
                                                            if (!isNaN(runs)) {
                                                                return total + runs;
                                                            } else {
                                                                // If currentItem.runs is not a valid number, return total unchanged
                                                                return total;
                                                            }
                                                        }, 0)}
                                                        /{bowlingInnings2.reduce((total, currentItem) => total + parseInt(currentItem.wickets), 0)}
                                                        <span className='ms-2'>
                                                            Overs ({bowlingInnings2.reduce((total, currentItem) => total + parseFloat(currentItem.overs), 0)} /20)
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div className='card my-2 shadow'>
                                    <div className='card-body p-0'>
                                    <table className="table table-bordered table-sm m-0">
                                            <thead>
                                                <tr>
                                                    <th colSpan={6} valign='middle'> {matchStats.team1_shortcode} Bowling Innings</th>
                                                </tr>
                                                <tr>
                                                    <th>Player Name</th>
                                                    <th>Overs</th>
                                                    <th>Maidens</th>
                                                    <th>Dots</th>
                                                    <th>Runs</th>
                                                    <th>Wickets</th>
                                                    <th>Economy</th>
                                                    <th>Fours</th>
                                                    <th>Sixes</th>
                                                    <th>Nb</th>
                                                    <th>Wides</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-group-divider">

                                                {bowlingInnings2.map((items, index) => {
                                                    return <tr key={index}>
                                                        <td>{items.player_name}</td>
                                                        <td>{parseFloat(items.overs).toFixed(1)}</td>
                                                        <td>{items.maidens}</td>
                                                        <td>{items.dots_bowled}</td>
                                                        <td>{items.runs_given}</td>
                                                        <td>{items.wickets}</td>
                                                        <td>{items.economy_rate}</td>
                                                        <td>{items.fours_given}</td>
                                                        <td>{items.sixes_given}</td>
                                                        <td>{items.nb_bowled}</td>
                                                        <td>{items.wd_bowled}</td>
                                                    </tr>
                                                })}
                                                    
                                            </tbody>
                                        </table>
                                    </div>
                                </div>                          
                            </div>
                        </div>
                    </section>  
                </main>
            </div>
            <div className='col-md-3'>
                <aside>
                    <div className='card mb-2'>
                        <div className='card-body p-0'>
                        <div className='card-title px-3 pt-2 text-center'>
                            WPL 2024 - 6's &amp; 4's
                        </div>
                        <div className='row m-0'>
                            <div className='col-sm-6 p-0'>
                                <div className='total-sixes text-center text-white  fs-2'>
                                {todaySfs.total_sixes}
                                </div>
                                <div className='total-sixes text-center text-white'>
                                <small>Total Sixes</small>
                                </div>
                            </div>
                            <div className='col-sm-6 p-0'>
                                <div className='total-fours text-center text-white fs-2'>
                                {todaySfs.total_fours}
                                </div>
                                <div className='total-fours text-center text-white'>
                                <small>Total Fours</small>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className='card team-table shadow'>
                        <div className='card-body p-0'>
                            <div className='card-title px-2 pt-2'>Women's Premier League Table 2024</div>
                            <div className='table-responsive table-sm'>
                            <table className='table table-bordered table-striped mb-0'>
                                <thead>
                                <tr>
                                    <th>TEAM</th>
                                    <th>M</th>
                                    <th>W</th>
                                    <th>L</th>
                                    <th>PT</th>
                                    <th>NRR</th>
                                </tr>
                                </thead>
                                <tbody>
                                {pointsTable.map( (items, index) => (
                                    <tr key={index}>
                                        <td>{items.team}</td>
                                        <td>{items.m}</td>
                                        <td>{items.w}</td>
                                        <td>{items.l}</td>
                                        <td>{items.pt}</td>
                                        <td>{items.nrr}</td>
                                    </tr>                
                                    ))}
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                    <div className='my-3' >
                    <h3 className='m-0 fs-6 text-white bg-info p-2'>Latest News &amp; Updates</h3>
                    {
                        posts && posts.map((item, index) => (
                            <div className="card shadow h-100 mb-2" key={index}>
                            <img src={`http://wplcricinfo.com/wpl/admin/${item.imgUrl}`} className="card-img-top" alt={item.title} />
                            <div className="card-body p-2 h-100">
                                <h3 className="card-title fs-6"><Link to={`/post/${item.id}`}>{item.title}</Link></h3>
                                <p className="card-text"><small className="text-body-secondary">Posted on: {CommonFunctions.getPostDateFormat(item.created_at)}</small></p>
                            </div>
                            </div>
                        ))
                    }
                </div> 
                </aside>
            </div>
        </div>
    </div>
    </React.Fragment>
  )
}

export default MatchDetails