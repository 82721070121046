import React from 'react'
import { Link } from 'react-router-dom'
import Navigationbar from '../components/Navigationbar'
import Footer from '../components/Footer'

const MatchRecords = () => {
  return (
    <React.Fragment>
        <div className="bg-fluid"></div>
        <Navigationbar />
        <nav aria-label="breadcrumb" className='mx-3'>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/" className="text-muted text-decoration-none">Home</Link></li>
            <li className="breadcrumb-item text-white active" aria-current="page">Match Records</li>
          </ol>
      </nav>
      <Footer />
    </React.Fragment>
  )
}

export default MatchRecords