import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Navigationbar from '../components/Navigationbar'
import Footer from '../components/Footer';
import Apiservice from '../services/Apiservice';

const TeamSquads = () => {

  const [tournamentId, setTournamentId] = useState(2); // Set your default tournament ID here
  const [teams, setTeams] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch teams and players data from your PHP API using Axios
        const response = await Apiservice.getTeamSquads(tournamentId)
        // Update the state with the fetched data
        setTeams(response.data);
        setError(null);
      } catch (error) {
        // Handle errors
        setError('Error fetching data');
        console.error('Error fetching data:', error);
      }
    };

    // Call fetchData function
    fetchData();
  }, [tournamentId]);

  return (
    <React.Fragment>
        <div className="bg-fluid"></div>
        <Navigationbar />
        <div className='container-fluid'>
        <nav aria-label="breadcrumb" className='mx-3'>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/" className="text-muted text-decoration-none">Home</Link></li>
            <li className="breadcrumb-item text-white active" aria-current="page">WPL Team Squads</li>
          </ol>
        </nav>

          <div className='row'>
            <div className='col-md-12'>
              <h1 className='fs-4 text-white'>Women's Premier League 2024 Team Squads</h1>
              <div className='card'>
                <div className='card-body'>
                  {/* <select value={tournamentId} onChange={(e) => setTournamentId(e.target.value)}>
                    <option value="1">Tournament 1</option>
                    <option value="2">Tournament 2</option>
                  </select> */}
                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    {teams.map((team, index) => (
                      <li className="nav-item" role="presentation" key={index}>
                        <button className={`nav-link ${index === 0 ? 'active' : ''}`} id={`pills-tab-${index}`} data-bs-toggle="pill" data-bs-target={`#pills-pane-${index}`} type="button" role="tab" aria-controls={`pills-pane-${index}`} aria-selected={index === 0}>
                          {team.team_shortcode}
                        </button>
                      </li>
                    ))}
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    {teams.map((team, index) => (
                      <div className={`tab-pane fade ${index === 0 ? 'show active' : ''}`} id={`pills-pane-${index}`} role="tabpanel" aria-labelledby={`pills-tab-${index}`} key={index}>
                        <h2 className='fs-5 mb-4'>{team.team_name} Team Squad</h2>
                        <div className='row'>
                          {team.players.map((player, i) => (
                            <div className='col-md-3' key={i}>
                              <div className="d-flex shadow border rounded mb-3">
                                <div className="flex-shrink-0">
                                  <img className={`team-${team.team_shortcode.toLowerCase()}`} src={`images/players/${(player.player_name).toLowerCase().replace(/\s+/g, '-')}.webp`} alt={`${player.player_name}`} style={{ width: '75px' }} onError={(e) => { e.target.onerror = null; e.target.src = 'images/players/default.webp'; }} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className='mb-0'>{player.player_name}</p>
                                  {(player.role !== null) ? <React.Fragment><small className='text-muted'>{player.role}</small><br/></React.Fragment> : ''}
                                  <small className='text-muted'>{player.country}</small>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>                                
                </div>
              </div>
            </div>
          </div>
        </div>
      <Footer />
    </React.Fragment>
  )
}

export default TeamSquads
