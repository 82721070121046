import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'
import Apiservice from '../services/Apiservice';
import Navigationbar from '../components/Navigationbar';
import Footer from '../components/Footer';

const PostDetailComponent = () => {
  const [post, setPost] = useState(null);
  const [postTitle, setPostTitle] = useState('');
  // Access URL parameters using useParams
  const { id } = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      try {
          const postId = id;
          const response = await Apiservice.getBlogPostsById(postId)
          setPost(response.data);
          setPostTitle(response.data.title);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [id]); // Include match.params.id in the dependency array

  return (
    <React.Fragment>
        <div className="bg-fluid"></div>
        <Navigationbar />
        <div className='container-fluid'> 
          <nav aria-label="breadcrumb" className=''>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" className="text-muted text-decoration-none">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/wpl-news-updates" className="text-muted text-decoration-none">WPL News &amp; Updates</Link></li>
                    <li className="breadcrumb-item text-white active" aria-current="page">{postTitle}</li>
                </ol>
            </nav>     
          <div className='row'>      
            <div className='col-md-12'>      
                {post ? (
                  <div className='card'>
                    <div className="row g-0">
                      <div className="col-md-4">
                          <img src={`https://wplcricinfo.com/wpl/admin/${post.imgUrl}`} alt="Post" className='img-fluid' />
                      </div>    
                      <div class="col-md-8">
                        <div class="card-body">
                          <h2 class="card-title fs-5 fw-bold">{post.title}</h2>
                          <div dangerouslySetInnerHTML={{ __html: post.content }} ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  ) : (
                    <p>Loading...</p>
                  )}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default PostDetailComponent;
