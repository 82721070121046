import React from 'react'
import { Link } from "react-router-dom";

import Navigationbar from '../components/Navigationbar';
import Footer from '../components/Footer';

const NoPage = () => {
  return (
      <React.Fragment>
            <div className='bg-fluid bg-ht-adj'></div>
              <Navigationbar />
              <nav aria-label="breadcrumb" className='mt-2'>
                  <div className="container-fluid">
                  <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/" className='text-decoration-none text-white'>Home</Link></li>
                      <li className="breadcrumb-item active  text-white" aria-current="page">Page Not Found</li>
                  </ol>
                  </div>
              </nav>
              <div className="d-flex align-items-center justify-content-center vh-75">
                <div className="text-center text-dark">
                    <h1 className="display-1  fw-bold">404</h1>
                    <p className="fs-3"> <span className="text-danger">Opps!</span> Page not found.</p>
                    <p className="lead">
                        The page you're looking for doesn't exist.
                      </p>
                    <Link to="/" className="btn btn-primary">Go Home</Link>
                </div>
            </div>
        <Footer />
      </React.Fragment>
  )
}

export default NoPage