import React from 'react'
import { Link } from 'react-router-dom'
import Navigationbar from '../components/Navigationbar'
import Footer from '../components/Footer'

const WplPointsTable = () => {
    
  return (
    <React.Fragment>
        <div className="bg-fluid"></div>
        <Navigationbar />
        <div className='container-fluid'>
            <nav aria-label="breadcrumb" className='mx-3'>
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" className="text-muted text-decoration-none">Home</Link></li>
                <li className="breadcrumb-item text-white active" aria-current="page">WPL Points Table</li>
            </ol>
            </nav>

            <div className='row'>
            <div className='col-md-12'>
              <h1>WPL Points Table</h1>
              <div className='card'>
                <div className='card-body'>
                                 
                </div>
              </div>
            </div>
          </div>
        </div>
      <Footer />
    </React.Fragment>
  )
}

export default WplPointsTable