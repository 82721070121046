import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Apiservice from '../services/Apiservice';
import Navigationbar from '../components/Navigationbar';
import Footer from '../components/Footer'
import CommonFunctions from '../components/CommonFunctions';

const BlogPosts = () => {
  // State to hold posts
  const [posts, setPosts] = useState([]);
  const [featuredPosts, setFeaturedPosts] = useState([]);

  // Fetch posts from the PHP script using Axios
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Apiservice.getBlogPosts()
        const data = response.data;
        setFeaturedPosts(data.featured_posts);
        setPosts(data.posts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs only once

  return (
    <React.Fragment>
        <div className="bg-fluid"></div>
        <Navigationbar />
        <div className='container-fluid'>
            <nav aria-label="breadcrumb" className=''>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" className="text-muted text-decoration-none">Home</Link></li>
                    <li className="breadcrumb-item text-white active" aria-current="page">WPL News &amp; Updates</li>
                </ol>
            </nav>

            <div className='row'>
                <div className='col-md-12'>
                    <h1 className='fs-5 text-white mb-3'>Women Premier League News &amp; Updates</h1>
                </div>
                <div className='col-md-12'>
                    <div className='row mb-4'>
                    {featuredPosts.length > 0 && (
                        <React.Fragment>
                            {featuredPosts.map((featuredPost, index) => (
                                <div className='col-md-6'>
                                    <div class="card h-100 shadow" key={index}>
                                        <img src={`https://wplcricinfo.com/wpl/admin/${featuredPost.imgUrl}`} alt="Featured Post" />
                                        <div class="card-body">
                                            <h2 class="card-title fs-6">{featuredPost.title}</h2>                                           
                                        </div>
                                        <div className='card-footer'><Link to={`/post/${featuredPost.id}`}>Read More...</Link></div>
                                    </div>
                                </div>
                            ))}
                        </React.Fragment>   
                    )}
                    </div>                
                </div>                
                {posts.map(post => (
                    <div className='col-md-4 mb-3'>
                        <div key={post.id} className="card h-100 shadow">
                            <img src={`https://wplcricinfo.com/wpl/admin/${post.imgUrl}`} alt="Post" className='img-fluid' />
                            <div className='card-body'>
                                <h3 className='card-title fs-6'>{post.title}</h3>
                                <Link to={`/post/${post.id}`}>Read More...</Link>
                                <div dangerouslySetInnerHTML={{ __html: post.content }} />
                            </div>
                            <div className='card-footer small text-muted text-end'>Date Posted on: {CommonFunctions.getPostDateFormat(post.created_at)}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <Footer />
    </React.Fragment>
  );
};

export default BlogPosts;