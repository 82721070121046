import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Navigationbar from '../components/Navigationbar'
import Slider from 'react-slick';
import Footer from '../components/Footer';
import Apiservice from '../services/Apiservice';
import CommonFunctions from '../components/CommonFunctions';


const Homepage = () => {

  const [tournamentId, setTournamentId] = useState('2')
  const [resultedMatches, setResultedMatches] = useState([])
  const [todaySfs, setTodaySfs] = useState([])
  const [posts, setPosts] = useState([])  
  const [playerBdays, setPlayerBdays] = useState([])  
  const [previousData, setPreviousData] = useState({})
  const [pointsTable, setPointsTable] = useState([])
  const [topBatters, setTopBatters] = useState([])
  const [topBowlers, setTopBowlers] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Apiservice.getMatchFixtures(tournamentId);
        setPlayerBdays(response.data.bdays);
        setPreviousData(response.data.previous_data);
        setResultedMatches(response.data.resulted_matches);
        setTodaySfs(response.data.total_sfs[0]);
        setPointsTable(response.data.points_table);
        setTopBatters(response.data.topbatters);
        setTopBowlers(response.data.topbowlers);
        setPosts(response.data.posts);
      } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [tournamentId]);
  


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay speed in milliseconds
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay speed in milliseconds
  };

  
  return (
    
    <React.Fragment>
      <div className='bg-fluid'></div>
        <Navigationbar />
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
                <article className=''>
                  <div className='mx-4 mb-3'>
                    <Slider {...settings}>
                      <div className='p-1'>
                        <img src="images/banner/dc-w_wpl_2024_banner_wplcricinfo.webp" alt="dc-w_wpl_2024_banner_wplcricinfo" className='img-fluid' />
                      </div>
                      <div className='p-1'>
                        <img src="images/banner/gg-w_wpl_2024_banner_wplcricinfo.webp" alt="gg-w_wpl_2024_banner_wplcricinfo" className='img-fluid' />
                      </div>
                      <div className='p-1'>
                        <img src="images/banner/mi-w_wpl_2024_banner_wplcricinfo.webp" alt="mi-w_wpl_2024_banner_wplcricinfo" className='img-fluid' />
                      </div>
                      <div className='p-1'>
                        <img src="images/banner/rcb-w_wpl_2024_banner_wplcricinfo.webp" alt="rcb-w_wpl_2024_banner_wplcricinfo" className='img-fluid' />
                      </div>
                      <div className='p-1'>
                        <img src="images/banner/upw-w_wpl_2024_banner_wplcricinfo.webp" alt="upw-w_wpl_2024_banner_wplcricinfo" className='img-fluid' />
                      </div>
                    </Slider>
                  </div>
                </article>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-3'>
              <aside>
                <div className='card mt-4 match-fixtures shadow'>
                <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-upcoming-tab" data-bs-toggle="pill" data-bs-target="#pills-upcoming" type="button" role="tab" aria-controls="upcoming-home" aria-selected="true">Upcoming</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-results-tab" data-bs-toggle="pill" data-bs-target="#pills-results" type="button" role="tab" aria-controls="pills-results" aria-selected="false">Results</button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show" id="pills-upcoming" role="tabpanel" aria-labelledby="pills-upcoming-tab" tabIndex="0">                  
                      <ul className='list-group list-group-flush'>
                          <li className='list-group-item px-2'>No More Matches. Wait for the next season.</li>
                      </ul>
                    </div>

                  <div className="tab-pane fade show active" id="pills-results" role="tabpanel" aria-labelledby="pills-results-tab" tabIndex="0">                  
                      <ul className='list-group list-group-flush'>
                      {resultedMatches && resultedMatches.map((match, index) => (
                          <li className='list-group-item px-2' key={index}>
                              <div className='team-title'>
                                  <div className="d-flex align-items-top">
                                      <div className="flex-grow ms-2">
                                          <div className='match-date'>
                                              {(match.formatted_match_no == '21st' ? 'Eliminator' : (match.formatted_match_no == '22nd') ? 'Finals' : match.formatted_match_no)} Match (N) {CommonFunctions.getDateFormat(match.match_date)} 
                                              {/* {CommonFunctions.getFormattedTime(item.match_time)} */}
                                          </div>
                                          <div>
                                              <Link to={`/match/${match.match_id}/${(match.team1_name).toLowerCase().replace(/\s+/g, '-')}-vs-${(match.team2_name).toLowerCase().replace(/\s+/g, '-')}`} >
                                                <strong title={match.team1_name}>{(match.winner_id == match.team1_id) ? <i className="bi bi-trophy text-info"></i> : '' } {match.team1_shortcode}</strong>
                                                <small className='text-muted px-1'>[vs]</small>
                                                <strong title={match.team2_name}>{match.team2_shortcode} {(match.winner_id == match.team2_id) ? <i className="bi bi-trophy text-info"></i> : '' } </strong>
                                              </Link>
                                          </div>
                                          <div className='match-venue'>{match.venue_name}, {match.city}</div>
                                          {previousData[`match_id_${match.match_id}`].map(team => (
                                            <span key={team.team_id}>
                                              <span className='pe-2 small'>{team.team_shortcode}: {parseInt(team.total_scored_runs) + parseInt(team.total_extras)}/{team.total_out_count} </span>                                              
                                            </span>
                                          ))}
                                      </div>
                                  </div>
                              </div>
                          </li>
                        ))}

                      </ul>
                    </div>
                  </div>
                  <div className="card-footer">
                      <Link to="/fixtures-and-results" >Fixtures &amp; Results</Link>
                  </div>
                </div>
                
                <div className='card mt-4 match-fixtures shadow'>
                <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-runs-tab" data-bs-toggle="pill" data-bs-target="#pills-runs" type="button" role="tab" aria-controls="runs-results" aria-selected="true">Top Run Getters</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-wickets-tab" data-bs-toggle="pill" data-bs-target="#pills-wickets" type="button" role="tab" aria-controls="wickets-results" aria-selected="false">Top Wicket Takers</button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-runs" role="tabpanel" aria-labelledby="pills-runs-tab" tabIndex="0">                  
                      <ul className='list-group list-group-flush'>
                      {topBatters && topBatters.map((item, index) => (
                          <li className={`list-group-item px-2 ${(item.rank == '1' ? 'orange-cap' : '')}`} key={index}>
                              <div className='team-title'>
                                  <div className="d-flex align-items-top">
                                      <div className="flex-shrink-0">
                                          <div className='ranking-badge'>
                                              {item.rank}
                                          </div>
                                          <img src={`images/players/${(item.full_name).toLowerCase().replace(/\s+/g, '-')}.webp`} alt={item.full_name} style={{width: '65px'}} className='bg-white border border-primary-subtle rounded-circle' />
                                      </div>
                                      <div className="flex-grow-2 ms-3">
                                          <div>
                                              <small className=''>{item.team_shortcode}</small><br/>
                                              <strong title={item.full_name}><Link to={`${item.player_id}/${(item.full_name).toLowerCase().replace(/\s+/g, '-')}`} >{item.full_name}</Link></strong>
                                          </div>
                                          <div className='match-venue'> {item.total_runs_scored} Runs</div>
                                      </div>
                                  </div>
                              </div>
                          </li>
                        ))}

                      </ul>
                    </div>

                  <div className="tab-pane fade show" id="pills-wickets" role="tabpanel" aria-labelledby="pills-wickets-tab" tabIndex="0">                  
                      <ul className='list-group list-group-flush'>
                      {topBowlers && topBowlers.map((item, index) => (
                          <li className={`list-group-item px-2 ${(item.rank == '1' ? 'purple-cap' : '')}`} key={index}>
                              <div className='team-title'>
                                  <div className="d-flex align-items-top">
                                      <div className="flex-shrink-0">
                                          <div className='ranking-badge'>
                                              {item.rank}
                                          </div>
                                          <img src={`images/players/${(item.full_name).toLowerCase().replace(/\s+/g, '-')}.webp`} alt={item.full_name} style={{width: '65px'}} className='bg-white border border-primary-subtle rounded-circle' />
                                      </div>
                                      <div className="flex-grow-2 ms-3">
                                          <div>
                                              <small className=''>{item.team_shortcode}</small><br/>
                                              <strong title={item.full_name}><Link to={`${item.player_id}/${(item.full_name).toLowerCase().replace(/\s+/g, '-')}`} >{item.full_name}</Link></strong>
                                          </div>
                                          <div className='match-venue'> {item.total_wickets} Wickets</div>
                                      </div>
                                  </div>
                              </div>
                          </li>
                        ))}

                      </ul>
                    </div>
                  </div>
                </div>
              </aside>
            </div>

            <div className='col-md-6'>  

                <div className='row'>
                  <div className='col-md-12'>
                        <div className='card'>
                          <img src='http://wplcricinfo.com/wpl/admin/uploads/wpl-champions-rcb-2024_wplcricinfo.jpg' alt="WPL Season 2 2024 Winners RCB-W" />
                          <div className='card-body bg-dark'>
                            <h1 className='fs-6 m-0 text-white'>Women's Premier League Season 02 2024 Winner is Royal Challengers Bangalore Women</h1>
                          </div>
                        </div>
                  </div>
                </div>

                <div className='row text-center my-3'>
                    <div className='col'>
                      <img src="images/logos/dc-w-sm_wplcricinfo.jpg" alt="dc-w-sm_wplcricinfo" className='border border-3 rounded-circle shadow' />
                      <div>DC-W</div>
                    </div>
                    <div className='col'>
                      <img src="images/logos/gg-w-sm_wplcricinfo.jpg" alt="gg-w-sm_wplcricinfo" className='border border-3 rounded-circle shadow' />
                      <div>GGW-W</div>
                    </div>
                    <div className='col'>
                      <img src="images/logos/mi-w-sm_wplcricinfo.jpg" alt="mi-w-sm_wplcricinfo" className='border border-3 rounded-circle shadow' />
                      <div>MI-W</div>
                    </div>
                    <div className='col'>
                      <img src="images/logos/rcb-w-sm_wplcricinfo.jpg" alt="rcb-w-sm_wplcricinfo" className='border border-3 rounded-circle shadow' />
                      <div>RCB-W</div>
                    </div>
                    <div className='col'>
                      <img src="images/logos/upw-w-sm_wplcricinfo.jpg" alt="upw-w-sm_wplcricinfo" className='border border-3 rounded-circle shadow' />
                      <div>UPW-W</div>
                    </div>
                </div> 

                <div className='row'>
                  {
                    posts && posts.map((item, index) => (
                      <div className='col-md-6 mb-4' key={index}>
                        <div className="card shadow h-100">
                          <img src={`https://wplcricinfo.com/wpl/admin/${item.imgUrl}`} className="card-img-top" alt={item.title} />
                          <div className="card-body p-2 h-100">
                            <h3 className="card-title fs-6"><Link to={`/post/${item.id}`}>{item.title}</Link></h3>
                          </div>
                          <div className="card-footer"><small className="text-body-secondary">Posted on: {CommonFunctions.getPostDateFormat(item.created_at)}</small></div>
                        </div>
                      </div>                      
                    ))
                  }
                </div>

            </div>
            <div className='col-md-3'>
              <aside>             
              <div className='card mt-4 mb-2'>
                <div className='card-body p-0'>
                  <div className='card-title px-3 pt-2 text-center'>
                    WPL 2024 - 6's &amp; 4's
                  </div>
                  <div className='row m-0'>
                      <div className='col-sm-6 p-0'>
                        <div className='total-sixes text-center text-white  fs-2'>
                          {todaySfs.total_sixes}
                        </div>
                        <div className='total-sixes text-center text-white'>
                          <small>Total Sixes</small>
                        </div>
                      </div>
                      <div className='col-sm-6 p-0'>
                        <div className='total-fours text-center text-white fs-2'>
                          {todaySfs.total_fours}
                        </div>
                        <div className='total-fours text-center text-white'>
                          <small>Total Fours</small>
                        </div>
                      </div>
                  </div>
                </div>
              </div>

              <div className='card border shadow mb-2'>
                <img src="images/banner/wpl-2024-trophy.webp" style={{width: '100%'}} />
              </div>

                <div className='card team-table shadow'>
                  <div className='card-body p-0'>
                    <div className='card-title px-2 pt-2'>Women's Premier League Table 2024</div>
                    <div className='table-responsive table-sm'>
                      <table className='table table-bordered table-striped mb-0'>
                        <thead>
                          <tr>
                            <th>TEAM</th>
                            <th>M</th>
                            <th>W</th>
                            <th>L</th>
                            <th>PT</th>
                            <th>NRR</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pointsTable.map( (items, index) => (
                            <tr key={index}>
                                <td>{items.team}</td>
                                <td>{items.m}</td>
                                <td>{items.w}</td>
                                <td>{items.l}</td>
                                <td>{items.pt}</td>
                                <td>{items.nrr}</td>
                              </tr>                
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className='bday-slider my-2'>
                  <div className='card shadow'>
                    <div className='card-title text-center text-white m-0'>
                      HAPPY BIRTHDAY !! 
                    </div>
                    <div className='card-body p-0 pb-5'>
                      <Slider {...settings2}>
                        {
                          playerBdays && playerBdays.map((item, index) => (
                            <div className='' key={index}>
                              <div className='d-flex justify-content-center mb-2'>
                                <img src={`images/players/${(item.full_name).toLowerCase().replace(/\s+/g, '-')}.webp`} alt={item.full_name} style={{width:'160px'}} onError={(e) => { e.target.onerror = null; e.target.src = 'images/players/default.webp'; }} />
                              </div>
                              <div className='text-center text-white'>
                                <h4 className='fs-5 m-0'>{item.full_name}</h4>
                                <small>{item.country}</small><br/>
                                <small>{item.formatted_date_of_birth}</small>
                              </div>
                            </div>
                        ))}
                      </Slider>
                  </div>            
                  </div>            
                </div>            
              </aside>
            </div>
          </div>
        </div>
        <Footer />
    </React.Fragment>
  )
}

export default Homepage