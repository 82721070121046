import React from 'react'

const Footer = () => {
  return (
    <footer>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 text-center'>
                    &copy; COPYRIGHT 2024 WPLCricinfo. ALL RIGHTS RESERVED.
                </div>
            </div>
        </div>    
    </footer>
  )
}

export default Footer