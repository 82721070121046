import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css'
import Homepage from "./pages/Homepage";
import FixturesAndResults from "./pages/FixturesAndResults";
import TeamInfos from "./pages/TeamInfos";
import TeamSquads from "./pages/TeamSquads";
import WplPointsTable from "./pages/WplPointsTable";
import MatchRecords from "./pages/MatchRecords";
import BlogPosts from "./pages/BlogPosts";
import PostDetailComponent from "./pages/PostDetailComponent";
import PlayerDetails from "./pages/PlayerDetails";
import MatchDetails from "./pages/MatchDetails";
import NoPage from "./pages/NoPage";

function App() {
  return (
      <BrowserRouter>
       <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/fixtures-and-results" element={<FixturesAndResults />} />
          <Route exact path="/wpl-teams" element={<TeamInfos />} />
          <Route exact path="/wpl-team-squads" element={<TeamSquads />} />
          <Route exact path="/wpl-points-table" element={<WplPointsTable />} />
          <Route exact path="/wpl-Records" element={<MatchRecords />} />
          <Route exact path="/wpl-news-updates" element={<BlogPosts />} />
          <Route exact path="/post" element={<BlogPosts />} />
          <Route exact path="/post/:id" element={<PostDetailComponent />} />
          <Route exact path="/:id/:player-name" element={<PlayerDetails />} />
          <Route exact path="/match/:id/:wplmatch" element={<MatchDetails />} />
          <Route exact path="/match" element={<FixturesAndResults />} />
          <Route exact path="/match/:id" element={<FixturesAndResults />} />
          <Route path="*" element={<NoPage />} />
       </Routes>
      </BrowserRouter>
  );
}

export default App;
