import axios from 'axios'

const API_URL = "https://wplcricinfo.com/"
// const API_URL = "http://localhost/wpl/"

class Apiservice{

    getMatchFixtures(tournamentId){
        return axios.get(API_URL + "api/getwplstats.php?tournament_id=" + tournamentId)
    }
    
    getwplteaminfo(tournamentId){
        return axios.get(API_URL + "api/getwplteaminfo.php?tournament_id=" + tournamentId)
    }
    
    getallfixturesresults(tournamentId){
        return axios.get(API_URL + "api/getallfixturesresults.php?tournament_id=" + tournamentId)
    }
    
    getTeamSquads(tournamentId){
        return axios.get(API_URL + "api/getwplteamsquads.php?tournament_id=" + tournamentId)
    }
   
    getBlogPosts(){
        return axios.get(API_URL + "api/getblogposts.php")
    }
    
    getBlogPostsById(postId){
        return axios.get(API_URL + "api/getblogposts.php?id=" + postId)
    }
   
    getPlayerById(player_id, tournament_id){
        return axios.get(API_URL + "api/getplayerbyid.php?player_id=" + player_id + "&tournament_id=" + tournament_id)
    }
    
    getmatchdetails(postId, tournament_id){
        return axios.get(API_URL + "api/getmatchdetails.php?match_id=" + postId + "&tournament_id=" + tournament_id)
    }

}

export default new Apiservice