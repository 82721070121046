import React from 'react'
import { Link } from 'react-router-dom'
import { TEAMS } from './Constants'

const Navigationbar = () => {
    const getTeams = TEAMS
    //console.log(getTeams)
  return (
    <React.Fragment>
        <nav className="navbar navbar-expand-lg" data-bs-theme="dark">
            <div className="container-fluid">
                <Link to="/" className="navbar-brand">WPLCricinfo</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link to="/" className="nav-link">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/fixtures-and-results" className="nav-link">Fixtures &amp; Results</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/wpl-teams" className="nav-link">Teams</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/wpl-team-squads" className="nav-link">Squads</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to="/wpl-points-table" className="nav-link">Table</Link>
                        </li> */}
                        <li className="nav-item">
                            <Link to="/wpl-news-updates" className="nav-link">News &amp; Updates</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </React.Fragment>
  )
}

export default Navigationbar