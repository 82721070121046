import moment from "moment";

class CommonFunctions{
    getDateFormat(value){
        return moment(value).format('MMM DD, YYYY');
    }
    getPostDateFormat(value){
        return moment(value).format('Do MMMM, YYYY');
    }
    getTimeFormat(value){
        return moment(value, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    }
    getFormattedTime(value){
        return moment(value, 'HH:mm:ss').format('h:mm A');
    }
    getCurrentTime(value){
        return moment(value, 'HH:mm:ss')
    }
}

export default new CommonFunctions