import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Slider from 'react-slick';
import Navigationbar from '../components/Navigationbar'
import Footer from '../components/Footer';
import Apiservice from '../services/Apiservice';
import CommonFunctions from '../components/CommonFunctions';

const FixturesAndResults = () => {

  const [tournamentId, setTournamentId] = useState('2')
  const [upcomingMatches, setUpcomingMatches] = useState([])
  const [resultedMatches, setResultedMatches] = useState([])
  const [previousData, setPreviousData] = useState({})
  const [todaySfs, setTodaySfs] = useState([])
  const [topBatters, setTopBatters] = useState([])
  const [topBowlers, setTopBowlers] = useState([])
  const [pointsTable, setPointsTable] = useState([])
  const [posts, setPosts] = useState([])

  useEffect(() => {
    Apiservice.getallfixturesresults(tournamentId).then(
      (response) => {
        setUpcomingMatches(response.data.upcoming_matches)
        setPreviousData(response.data.previous_data);
        setResultedMatches(response.data.resulted_matches)
        setTodaySfs(response.data.total_sfs[0])
        setPointsTable(response.data.points_table)
        setPosts(response.data.posts)
        setTopBatters(response.data.topbatters);
        setTopBowlers(response.data.topbowlers);
      }
    )

    
  }, [tournamentId])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay speed in milliseconds
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <React.Fragment>
        <div className="bg-fluid"></div>
        <Navigationbar />
        <div className='container-fluid'>
        <nav aria-label="breadcrumb" className=''>
          <ol className="breadcrumb mb-1">
            <li className="breadcrumb-item"><Link to="/" className="text-muted text-decoration-none">Home</Link></li>
            <li className="breadcrumb-item text-white active" aria-current="page">Fixtures &amp; Results</li>
          </ol>
        </nav>
          <div className='row'>
            <div className='col-md-12'>
                <article className=''>
                  <div className='mx-4 mb-3'>
                    <Slider {...settings}>
                      <div className='p-1'>
                        <img src="images/banner/dc-w_wpl_2024_banner_wplcricinfo.webp" alt="dc-w_wpl_2024_banner_wplcricinfo" className='img-fluid' />
                      </div>
                      <div className='p-1'>
                        <img src="images/banner/gg-w_wpl_2024_banner_wplcricinfo.webp" alt="gg-w_wpl_2024_banner_wplcricinfo" className='img-fluid' />
                      </div>
                      <div className='p-1'>
                        <img src="images/banner/mi-w_wpl_2024_banner_wplcricinfo.webp" alt="mi-w_wpl_2024_banner_wplcricinfo" className='img-fluid' />
                      </div>
                      <div className='p-1'>
                        <img src="images/banner/rcb-w_wpl_2024_banner_wplcricinfo.webp" alt="rcb-w_wpl_2024_banner_wplcricinfo" className='img-fluid' />
                      </div>
                      <div className='p-1'>
                        <img src="images/banner/upw-w_wpl_2024_banner_wplcricinfo.webp" alt="upw-w_wpl_2024_banner_wplcricinfo" className='img-fluid' />
                      </div>
                    </Slider>
                  </div>
                </article>
            </div>
          </div>

          <main>
            <div className='row'>
              <div className='col-md-9'>
                <div className='card'>
                  <div className='card-body'>
                    <h1 className='fs-5 mb-2'>Women's Premier League 2024 Fixtures, Upcoming and Resulted Matches</h1>
                    <ul className="nav nav-pills mb-2" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Fixtures</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Results</button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div className="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                        <div>No more matches, Wait for next season to start!</div>                           
                      </div>
                      <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">
                            {resultedMatches.map((match, key) => (
                              <div className="card mb-2 shadow" key={match.match_id}>
                              <div className="card-header">
                              {(match.formatted_match_no == '21st' ? 'Eliminator' : (match.formatted_match_no == '22nd') ? 'Finals' : match.formatted_match_no)} WPL 2024 Match (N)
                              </div>
                              <div className="card-body">
                                <small className='text-muted'><img src={`images/logos/${(match.team1_shortcode).toLowerCase()}-sm_wplcricinfo.jpg`} style={{width: '35px'}} alt={match.team1_shortcode} /> <span className='mx-4'>vs</span> <img src={`images/logos/${(match.team2_shortcode).toLowerCase()}-sm_wplcricinfo.jpg`} style={{width: '35px'}} alt={match.team2_shortcode} /></small><br/>                           {previousData[`match_id_${match.match_id}`].map(team => (
                                    <span key={team.team_id}>
                                      <span className='pe-2 small'>{team.team_shortcode}: {parseInt(team.total_scored_runs) + parseInt(team.total_extras)}/{team.total_out_count} </span>                                              
                                    </span>
                                  ))}
                                <h2 className="card-title fs-5 my-2"><Link to={`/match/${match.match_id}/${(match.team1_name).toLowerCase().replace(/\s+/g, '-')}-vs-${(match.team2_name).toLowerCase().replace(/\s+/g, '-')}`} >{match.team1_name} vs {match.team2_name}</Link></h2>
                                <div className='float-end'>
                                  {(match.winner_id == match.team1_id) ? <React.Fragment>Winner: <i className="bi bi-trophy text-info"></i> <img src={`images/logos/${(match.team1_shortcode).toLowerCase()}-sm_wplcricinfo.jpg`} style={{width: '35px'}} alt={match.team1_shortcode} /></React.Fragment> : '' } 
                                  {(match.winner_id == match.team2_id) ? <React.Fragment>Winner: <i className="bi bi-trophy text-info"></i> <img src={`images/logos/${(match.team2_shortcode).toLowerCase()}-sm_wplcricinfo.jpg`} style={{width: '35px'}} alt={match.team2_shortcode} /></React.Fragment> : '' } 
                                </div>
                                <small className="card-text">
                                  <i className="bi bi-calendar-event text-primary pe-1"></i> {CommonFunctions.getDateFormat(match.match_date)} 
                                  {/* <i className="bi bi-clock text-primary  ps-4"></i> {CommonFunctions.getFormattedTime(match.match_time)} */}
                                </small>
                              </div>
                            </div> 
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
              <aside>             
              <div className='card mb-2'>
                <div className='card-body p-0'>
                  <div className='card-title px-3 pt-2 text-center'>
                    WPL 2024 - 6's &amp; 4's
                  </div>
                  <div className='row m-0'>
                      <div className='col-sm-6 p-0'>
                        <div className='total-sixes text-center text-white  fs-2'>
                          {todaySfs.total_sixes}
                        </div>
                        <div className='total-sixes text-center text-white'>
                          <small>Total Sixes</small>
                        </div>
                      </div>
                      <div className='col-sm-6 p-0'>
                        <div className='total-fours text-center text-white fs-2'>
                          {todaySfs.total_fours}
                        </div>
                        <div className='total-fours text-center text-white'>
                          <small>Total Fours</small>
                        </div>
                      </div>
                  </div>
                </div>
              </div>

                <div className='card team-table shadow'>
                  <div className='card-body p-0'>
                    <div className='card-title px-2 pt-2'>Women's Premier League Table 2024</div>
                    <div className='table-responsive table-sm'>
                      <table className='table table-bordered table-striped mb-0'>
                        <thead>
                          <tr>
                            <th>TEAM</th>
                            <th>M</th>
                            <th>W</th>
                            <th>L</th>
                            <th>PT</th>
                            <th>NRR</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pointsTable.map( (items, index) => (
                            <tr key={index}>
                                <td>{items.team}</td>
                                <td>{items.m}</td>
                                <td>{items.w}</td>
                                <td>{items.l}</td>
                                <td>{items.pt}</td>
                                <td>{items.nrr}</td>
                              </tr>                
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className='card mt-4 match-fixtures shadow'>
                <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-runs-tab" data-bs-toggle="pill" data-bs-target="#pills-runs" type="button" role="tab" aria-controls="runs-results" aria-selected="true">Top Run Getters</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-wickets-tab" data-bs-toggle="pill" data-bs-target="#pills-wickets" type="button" role="tab" aria-controls="wickets-results" aria-selected="false">Top Wicket Takers</button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-runs" role="tabpanel" aria-labelledby="pills-runs-tab" tabIndex="0">                  
                      <ul className='list-group list-group-flush'>
                      {topBatters && topBatters.map((item, index) => (
                          <li className={`list-group-item px-2 ${(item.rank == '1' ? 'orange-cap' : '')}`} key={index}>
                              <div className='team-title'>
                                  <div className="d-flex align-items-top">
                                      <div className="flex-shrink-0">
                                          <div className='ranking-badge'>
                                              {item.rank}
                                          </div>
                                          <img src={`images/players/${(item.full_name).toLowerCase().replace(/\s+/g, '-')}.webp`} alt={item.full_name} style={{width: '65px'}} className='bg-white border border-primary-subtle rounded-circle' />
                                      </div>
                                      <div className="flex-grow-2 ms-3">
                                          <div>
                                              <small className=''>{item.team_shortcode}</small><br/>
                                              <strong title={item.full_name}><Link to={`../${item.player_id}/${(item.full_name).toLowerCase().replace(/\s+/g, '-')}`} >{item.full_name}</Link></strong>
                                          </div>
                                          <div className='match-venue'> {item.total_runs_scored} Runs</div>
                                      </div>
                                  </div>
                              </div>
                          </li>
                        ))}

                      </ul>
                    </div>

                  <div className="tab-pane fade show" id="pills-wickets" role="tabpanel" aria-labelledby="pills-wickets-tab" tabIndex="0">                  
                      <ul className='list-group list-group-flush'>
                      {topBowlers && topBowlers.map((item, index) => (
                          <li className={`list-group-item px-2 ${(item.rank == '1' ? 'purple-cap' : '')}`} key={index}>
                              <div className='team-title'>
                                  <div className="d-flex align-items-top">
                                      <div className="flex-shrink-0">
                                          <div className='ranking-badge'>
                                              {item.rank}
                                          </div>
                                          <img src={`images/players/${(item.full_name).toLowerCase().replace(/\s+/g, '-')}.webp`} alt={item.full_name} style={{width: '65px'}} className='bg-white border border-primary-subtle rounded-circle' />
                                      </div>
                                      <div className="flex-grow-2 ms-3">
                                          <div>
                                              <small className=''>{item.team_shortcode}</small><br/>
                                              <strong title={item.full_name}><Link to={`../${item.player_id}/${(item.full_name).toLowerCase().replace(/\s+/g, '-')}`} >{item.full_name}</Link></strong>
                                          </div>
                                          <div className='match-venue'> {item.total_wickets_taken} Wickets</div>
                                      </div>
                                  </div>
                              </div>
                          </li>
                        ))}

                      </ul>
                    </div>
                  </div>
                  
                </div>
                <div className='my-3' >
                  <h3 className='m-0 fs-6 text-white bg-info p-2'>Latest News &amp; Updates</h3>
                  {
                    posts && posts.map((item, index) => (
                        <div className="card shadow h-100 mb-2" key={index}>
                          <img src={`http://wplcricinfo.com/wpl/admin/${item.imgUrl}`} className="card-img-top" alt={item.title} />
                          <div className="card-body p-2 h-100">
                            <h3 className="card-title fs-6"><Link to={`/post/${item.id}`}>{item.title}</Link></h3>
                            <p className="card-text"><small className="text-body-secondary">Posted on: {CommonFunctions.getPostDateFormat(item.created_at)}</small></p>
                          </div>
                        </div>
                    ))
                  }
                </div>                      
              </aside>
              </div>
            </div>
          </main>
        </div>
      <Footer />
    </React.Fragment>
  )
}

export default FixturesAndResults
