import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Navigationbar from '../components/Navigationbar'
import Footer from '../components/Footer'
import Apiservice from '../services/Apiservice'
import CommonFunctions from '../components/CommonFunctions';

const TeamInfos = () => {

  const [teams, setTeams] = useState([])
  const [pointsTable, setPointsTable] = useState([])
  const [fixturesResults, setFixturesResults] = useState([])
  const [tournamentDetails, setTournamentDetails] = useState([])
  const [tournamentId, setTournamentId] = useState('2')
  const [todaySfs, setTodaySfs] = useState([])
  const [posts, setPosts] = useState([]) 

  useEffect( () => {
    Apiservice.getwplteaminfo(tournamentId).then(
      (response) => {
        setTeams(response.data.teams)
        setPointsTable(response.data.points_table)
        setFixturesResults(response.data.fixtures)
        setTournamentDetails(response.data.tournament)
        setTodaySfs(response.data.total_sfs[0])
        setPosts(response.data.posts);
      }
    )
  }, [])

  return (
    <React.Fragment>
        <div className="bg-fluid"></div>
        <Navigationbar />
        <div className='container-fluid'>
          <nav aria-label="breadcrumb" className=''>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/" className="text-muted text-decoration-none">Home</Link></li>
              <li className="breadcrumb-item text-white active" aria-current="page">WPL Teams</li>
            </ol>
          </nav>

            <div className='row'>              
              <div className='col-md-9'>
                <h1 className='fs-4 text-white'>Women's Premier League Teams</h1>              
                <img src="images/banner/wpl_team_captains_wpl_cricinfo.webp" alt="WPL Team Captains" className='img-fluid rounded shadow mb-4' />
                  <div className='row'>
                  {teams.map( (items, index) => (
                    <div className='col-md-6' key={index}>
                      <div className={`card mb-3 shadow team-${items.team_shortcode.toLowerCase()} text-white`} >
                        <div className="row g-0">
                          <div className="col-md-2 text-center">
                            <img src={`images/logos/${items.team_shortcode.toLowerCase()}-lg-wplcricinfo.webp`} className="img-fluid rounded-start" alt={`${items.team_shortcode}`} />
                          </div>
                          <div className="col-md-10">
                            <div className="card-body py-1">
                              <span className="float-shortcode">{items.team_shortcode}</span>
                              <h2 className="card-title mb-0 fs-5">{items.team_name}</h2>
                              <p className="card-text small mb-0">Captain: <strong>{items.captain_fullname}</strong> {(items.team_shortcode !== 'RCB-W' && items.team_shortcode !== 'MI-W') ? <i className="bi bi-airplane icon-rotate"></i> : ''}</p>
                              <p className="card-text small mb-0">Coach: <strong>{items.coach}</strong></p>
                            </div>
                          </div>
                        </div>
                      </div>                    
                    </div>
                  ))}
                </div>

                <div className='col-md-12'>
                  <hr/>
                  <h2 className='fs-5'>News &amp; Updates</h2>
                  <div className='row'>
                    {
                      posts && posts.map((item, index) => (
                        <div className='col-md-3 mb-4' key={index}>
                          <div className="card shadow h-100">
                            <img src={`http://wplcricinfo.com/wpl/admin/${item.imgUrl}`} className="card-img-top" alt={item.title} />
                            <div className="card-body p-2 h-100">
                              <h3 className="card-title fs-6">{item.title}</h3>
                              <p className="card-text"><small className="text-body-secondary">Posted on: {CommonFunctions.getPostDateFormat(item.created_at)}</small></p>
                            </div>
                          </div>
                        </div>                      
                      ))
                    }
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <aside>
                <div className='card mb-2'>
                  <div className='card-body p-0'>
                    <div className='card-title px-3 pt-2 text-center'>
                      WPL 2024 - 6's &amp; 4's
                    </div>
                    <div className='row m-0'>
                        <div className='col-sm-6 p-0'>
                          <div className='total-sixes text-center text-white  fs-2'>
                            {todaySfs.total_sixes}
                          </div>
                          <div className='total-sixes text-center text-white'>
                            <small>Total Sixes</small>
                          </div>
                        </div>
                        <div className='col-sm-6 p-0'>
                          <div className='total-fours text-center text-white fs-2'>
                            {todaySfs.total_fours}
                          </div>
                          <div className='total-fours text-center text-white'>
                            <small>Total Fours</small>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                  <div className='card team-table shadow'>
                    <div className='card-body p-0'>
                      <div className='card-title px-2 pt-2'>Women's Premier League Table 2024</div>
                      <div className='table-responsive table-sm'>
                        <table className='table table-bordered table-striped mb-0'>
                          <thead>
                            <tr>
                              <th>TEAM</th>
                              <th>M</th>
                              <th>W</th>
                              <th>L</th>
                              <th>PT</th>
                              <th>NRR</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pointsTable.map( (items, index) => (
                              <tr key={index}>
                                  <td>{items.team}</td>
                                  <td>{items.m}</td>
                                  <td>{items.w}</td>
                                  <td>{items.l}</td>
                                  <td>{items.pt}</td>
                                  <td>{items.nrr}</td>
                                </tr>                
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>                  
                </aside>
              </div>
          </div>
        </div>
      <Footer />
    </React.Fragment>
  )
}

export default TeamInfos